import {Component, OnInit} from '@angular/core';
import {interval} from 'rxjs';
import * as $ from 'jquery';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CaruselComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    interval(5000).subscribe((val) => {
      if ($('.container')[0].getBoundingClientRect().bottom > 0) {
        const allSlides = $('.fs-slide');
        if (allSlides.eq(0).css('opacity') == 1) {
          $('#btn-next-2').click();
        } else if (allSlides.eq(1).css('opacity') == 1) {
          $('#btn-next-3').click();
        } else if (allSlides.eq(2).css('opacity') == 1) {
          $('#btn-next-1').click();
        }
      }
    });
  }

}

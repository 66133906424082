import {Component, HostListener, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {
  AdvancedLayout,
  Description,
  DescriptionStrategy,
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy
} from 'angular-modal-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  images: Image[];
  zoomed: Image[];
  customFullDescription: Description = {strategy: DescriptionStrategy.ALWAYS_HIDDEN};
  customPlainGalleryRowDescConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if ($('#modal-gallery-container') && this.scrWidth <= 600) {
      $('.close-image').click();
    }
  }

  constructor() {
    this.getScreenSize();
  }

  ngOnInit() {
    this.images = [
      new Image(0, {
        img: '../assets/gallery/zoomed/Point Blur_Aug192019_082647.webp'
      }, {
        img: '../assets/gallery/image1.webp'
      }),
      new Image(1, {
        img: '../assets/gallery/zoomed/Point Blur_Aug202019_133728.webp'
      }, {
        img: '../assets/gallery/image2.webp'
      }),
      new Image(2, {
        img: '../assets/gallery/zoomed/Point Blur_Aug202019_134153.webp'
      }, {
        img: '../assets/gallery/image5.jpg'
      }),
      new Image(3, {
        img: '../assets/gallery/zoomed/Point Blur_Aug202019_134337.webp'
      }, {
        img: '../assets/gallery/image7.webp'
      }),
      new Image(4, {
        img: '../assets/gallery/zoomed/Point Blur_Aug202019_134500.webp'
      }, {
        img: '../assets/gallery/image8.webp'
      }),
      new Image(5, {
        img: '../assets/gallery/zoomed/Point Blur_Aug202019_134558.webp'
      }, {
        img: '../assets/gallery/image9.webp'
      }),
      new Image(6, {
        img: '../assets/gallery/zoomed/Point Blur_Aug202019_134737.webp'
      }, {
        img: '../assets/gallery/image10.webp'
      }),
      new Image(7, {
        img: '../assets/gallery/zoomed/zoom-1.webp'
      }, {
        img: '../assets/gallery/image22.jpg'
      }),
      new Image(8, {
        img: '../assets/gallery/zoomed/zoom-2.webp'
      }, {
        img: '../assets/gallery/image21.jpg'
      }),
      new Image(9, {
        img: '../assets/gallery/zoomed/zoom-3.webp'
      }, {
        img: '../assets/gallery/image20.jpg'
      }),
      new Image(10, {
        img: '../assets/gallery/zoomed/zoom-4.webp'
      }, {
        img: '../assets/gallery/image19.jpg'
      }),
      new Image(11, {
        img: '../assets/gallery/zoomed/zoom-5.webp'
      }, {
        img: '../assets/gallery/image18.jpg'
      }),
      new Image(12, {
        img: '../assets/gallery/zoomed/zoom-6.webp'
      }, {
        img: '../assets/gallery/image17.jpg'
      }),
      new Image(13, {
        img: '../assets/gallery/zoomed/zoom-7.webp'
      }, {
        img: '../assets/gallery/image16.jpg'
      }),
      new Image(14, {
        img: '../assets/gallery/zoomed/zoom-8.webp'
      }, {
        img: '../assets/gallery/image15.jpg'
      }),
      new Image(15, {
        img: '../assets/gallery/zoomed/zoom-9.webp'
      }, {
        img: '../assets/gallery/image14.jpg'
      }),
      new Image(16, {
        img: '../assets/gallery/zoomed/zoom-10.webp'
      }, {
        img: '../assets/gallery/image13.jpg'
      }),
      new Image(17, {
        img: '../assets/gallery/zoomed/zoom-11.webp'
      }, {
        img: '../assets/gallery/image12.jpg'
      }),
      new Image(18, {
        img: '../assets/gallery/zoomed/zoom-12.webp'
      }, {
        img: '../assets/gallery/image11.jpg'
      })
    ];
  }

  openImageModalRowDescription(image: Image) {
    if (this.scrWidth > 600) {
      //console.log('Opening modal gallery from custom plain gallery row and description, with image: ', image);
      const index: number = this.getCurrentIndexCustomLayout(image, this.images);
      this.customPlainGalleryRowDescConfig = Object
        .assign({}, this.customPlainGalleryRowDescConfig, {layout: new AdvancedLayout(index, true)});
    }
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

  moveToSelected(element) {
    let selected;
    if (element === 'next') {
      console.log($('.selected-item').attr('id') === 'slide-item-19')
      if ($('.selected-item').attr('id') === 'slide-item-19') {
        selected = $('#slide-item-1');
      } else {
        selected = $('.selected-item').next();
      }
    } else if (element === 'prev') {
      if ($('.selected-item').attr('id') === 'slide-item-1') {
        selected = $('#slide-item-19');
      } else {
        selected = $('.selected-item').prev();
      }
    } else {
      selected = element;
    }

    let next = $(selected).next();
    let prev = $(selected).prev();
    let prevSecond = $(prev).prev();
    let nextSecond = $(next).next();

    // console.log(next.length);
    // if (next.length === 0){
    //   next = $('#slide-1');
    //   console.log(next);
    // }


    $(selected).removeClass().addClass('selected-item');

    $(prev).removeClass().addClass('prev');
    $(next).removeClass().addClass('next');

    $(nextSecond).removeClass().addClass('nextRightSecond');
    $(prevSecond).removeClass().addClass('prevLeftSecond');

    $(nextSecond).nextAll().removeClass().addClass('hideRight');
    $(prevSecond).prevAll().removeClass().addClass('hideLeft');

  }
}

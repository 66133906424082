import {Component, HostListener, Input, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  @Input() elements: HTMLElement[];

  constructor() {
  }

  ngOnInit() {

    $(window).on('resize', function () {
      if ($(window).width() > 900) {
        ($('#menuIcon').removeClass("inActive"));
        ($('#menuIcon').removeClass("active"));
        ($('ul').removeClass("active"));
      }
    });
  }

  populate() {
    let menu = document.getElementById("menuIcon");
    let list = document.querySelector('ul');

    menu.classList.toggle('active');
    list.classList.toggle('active');

    if (!(menu.classList.contains('active')) || menu.classList.contains("inActive")) {
      menu.classList.toggle('inActive');
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if ($(window).scrollTop()) {
      $('nav').addClass('black');
    } else {
      $('nav').removeClass('black');
    }
  }

  scroll(index: number) {
    this.elements[index].scrollIntoView({behavior: 'smooth', block: 'start'});
    this.populate();
  }


  makeActive(toMakeActive: string) {
    $('.active').removeClass('active');
    $('#' + toMakeActive).addClass('active');
  }
}

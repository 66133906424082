import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {CaruselComponent} from './carousel/carousel.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';

import {NavBarComponent} from './nav-bar/nav-bar.component';
import { AboutComponent } from './about/about.component';
import 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import 'mousetrap';
import { ModalGalleryModule } from 'angular-modal-gallery';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { WhatsAppButtonComponent } from './whats-app-button/whats-app-button.component';
import { NotificationComponent } from './notification/notification.component';
import {HomeContainerComponent} from './home-container/home-container.component';
import { RatesComponent } from './rates/rates.component';
import { GiftsComponent } from './gifts/gifts.component';
import {MatInputModule, MatNativeDateModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';

export class MyHammerConfig extends HammerGestureConfig  {
  overrides = {
    swipe: {velocity: 0.4, threshold: 20},
    pinch: { enable: false },
    rotate: { enable: false }
  } as any;
}

@NgModule({
  declarations: [
    AppComponent,
    CaruselComponent,
    NavBarComponent,
    AboutComponent,
    GalleryComponent,
    ContactsComponent,
    WhatsAppButtonComponent,
    NotificationComponent,
    HomeContainerComponent,
    RatesComponent,
    GiftsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MDBBootstrapModule.forRoot(),
    ModalGalleryModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  exports: [
    MatDatepickerModule
  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

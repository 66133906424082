import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavBarComponent} from '../nav-bar/nav-bar.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.scss']
})
export class HomeContainerComponent implements OnInit {

  @ViewChild(NavBarComponent, {static: false}) navBar: NavBarComponent;

  constructor() {
  }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (this.navBar) {

      if (this.isActiveElement($('#carouselContainer')[0])) {
        this.navBar.makeActive('a-home');
      } else if (this.isActiveElement($('#aboutComponent')[0])) {
        this.navBar.makeActive('a-about');
      } else if (this.isActiveElement($('#ratesComponent')[0])) {
        this.navBar.makeActive('a-rates');
      } else if (this.isActiveElement($('#giftsComponent')[0])) {
        this.navBar.makeActive('a-gifts');
      } else if (this.isActiveElement($('#galleryComponent')[0])) {
        this.navBar.makeActive('a-gallery');
      } else if (this.isActiveElement($('#contactsComponent')[0])) {
        this.navBar.makeActive('a-contacts');
      }
    }
  }

  isActiveElement(el): boolean {
    return (el.getBoundingClientRect().top <= 1 && el.getBoundingClientRect().bottom > 1);
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whats-app-button',
  templateUrl: './whats-app-button.component.html',
  styleUrls: ['./whats-app-button.component.scss']
})
export class WhatsAppButtonComponent implements OnInit {

  phoneNumber: string;

  constructor() { }

  ngOnInit() {

    this.phoneNumber = "37061376557";
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeContainerComponent} from '../home-container/home-container.component';
import {NotificationComponent} from '../notification/notification.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeContainerComponent,
  },
  { path: '',
    redirectTo: '/notification',
    pathMatch: 'full'
  },
  {
    path: 'notification',
    component: NotificationComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})

export class ContactsComponent implements OnInit {

  myFormGroup = new FormGroup({
    nameControl: new FormControl('', {
      validators: Validators.required
    }),
    emailControl: new FormControl('', {
      validators: [Validators.required, Validators.email]
    }),
    phoneNumberControl: new FormControl('', {
      validators: Validators.required
    }),
    bookingDateControl: new FormControl('', {
      validators: Validators.required
    }),
    bookingDurationControl: new FormControl('', {
      validators: Validators.required
    }),
    backContactControl: new FormControl('', {
      validators: Validators.required
    }),
    messageControl: new FormControl('')
  }, {
    updateOn: 'submit'
  });

  rates: string[] = [
    '1h - 300€',
    '2h - 600€',
    '3h - 800€',
    '4h - 1000€',
    'Overnight - 2500€',
    '24h - 3000€',
    '2 days - 5000€',
    'Every other day - 2000€'
  ];

  backContacts: string[] = [
    'Mobile phone / Call',
    'Mobile phone / SMS',
    'E-mail'
  ];

  minDate: Date;
  maxDate: Date;

  constructor() {
  }

  ngOnInit() {
    this.minDate = new Date();
    this.maxDate = new Date();

    this.maxDate.setMonth(this.minDate.getMonth() + 6);
  }


  processForm() {
    if(this.myFormGroup.valid){
    let bookingDateString = this.myFormGroup.get('bookingDateControl').value.toString();
    bookingDateString = bookingDateString
      .substring(0, bookingDateString.indexOf((new Date()).getFullYear().toString()) + 4);

    const data1 = '{\"name\": \"' + this.myFormGroup.get('nameControl').value + '\",' +
      '\"email\": \"' + this.myFormGroup.get('emailControl').value + '\",' +
      '\"phoneNumber\": \"' + this.myFormGroup.get('phoneNumberControl').value + '\",' +
      '\"bookingDate\": \"' + bookingDateString + '\",' +
      '\"bookingDuration\": \"' + this.myFormGroup.get('bookingDurationControl').value + '\",' +
      '\"backContact\": \"' + this.myFormGroup.get('backContactControl').value + '\",' +
      '\"message\": \"' + this.myFormGroup.get('messageControl').value + '\"}';


    //console.log(data1);
    $.ajax({
      type: 'POST',
      url: 'https://victoria-fox.com/mail.php',
      data: data1
    }).done(function (data2) {
      //console.log(data2);
      alert('email sent successfully');
    });
  }else{
    //console.log('not valid form');
  }
  }

  getErrorMessage() {
    return this.myFormGroup.get('emailControl').hasError('required') ? 'This field is required' :
      this.myFormGroup.get('emailControl').hasError('email') ? 'Not a valid email' :
        '';
  }

}
